a,
a:hover {
    text-decoration: none
}

.nav-tabs .nav-link,
body,
h1,
h2,
h3,
p {
    font-family: Montserrat, sans-serif
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

section {
    scroll-margin-top: 90px
}

h1,
h2,
h3 {
    font-size: 60px;
    font-weight: 300
}

p {
    font-size: 18px;
    font-weight: 400
}

a {
    color: var(--primary-color);
    transition: .3s
}

a:hover {
    color: rgba(var(--primary-color-rgb), .7)
}

.herofamily {
    width: 100%;
    height: 715px;
    position: relative;
    padding: 135px 0 100px
}

.herofamily img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1
}

.herofamily .carousel {
    position: absolute;
    top: 0;
    width: 100%
}

.herofamily .container {
    position: relative;
    z-index: 3
}

.navbar {
    transition: .5s
}

.header-wrapper .navbar-brand,
.offers {
    position: relative
}

.header-wrapper .navbar-brand img {
    position: absolute;
    top: -30px;
    width: 125px
}

.header-wrapper .fixed-top {
    padding: 30px 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .5) 50%, transparent 100%)
}

.header-wrapper .fixed-top .header-scrolled {
    background: 0 0
}

.ant-modal-root .ant-modal-wrap {
    z-index: 1050
}

.navbar-expand-lg .navbar-nav {
    flex-direction: row;
    width: 100%;
    float: right;
    align-items: flex-end;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.formdivfamily .form-control:focus,
.header-wrapper .navbar-toggler:focus {
    box-shadow: none
}

.header-wrapper .nav-item {
    margin: 0 10px
}

.header-wrapper .nav-item .nav-link {
    font-size: 16px;
    color: rgba(255, 255, 255, .7);
    font-weight: 400
}

.header-wrapper .nav-item .nav-link.active,
.header-wrapper .nav-item .nav-link:hover {
    font-weight: 500;
    border-bottom: 1px solid #fff;
    color: #fff
}

.header-scrolled {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #497b68 !important;
    box-shadow: 0 .25rem 0, 375rem rgba(12, 0, 46, .5);
    transition: .5s
}

.logo img {
    width: 130px
}

.downloadbrochurefamily {
    color: #fff;
    width: 16%;
    margin-left: 20px;
    border-bottom: 1px solid #fff
}

.header-wrapper .nav-item:last-child {
    margin-right: 0
}

input.form-control::placeholder {
    color: rgba(255, 255, 255, .5)
}

input.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

input.form-control ::-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

textarea.form-control::placeholder {
    color: rgba(255, 255, 255, .5)
}

textarea.form-control:-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

textarea.form-control ::-ms-input-placeholder {
    color: rgba(255, 255, 255, .5)
}

.formdivfamily {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(82, 82, 83, .8);
    padding: 60px 70px;
    width: 500px;
    height: 500px;
    border-radius: 100%
}

.formdivfamily .form-control {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    margin-bottom: 15px;
    height: 50px;
    color: #fff
}

.formdivfamily textarea.form-control {
    height: 70px
}

.formdivfamily h2 {
    font-weight: 300;
    color: #fff;
    text-align: center;
    font-size: 40px
}

.formdivfamily .form-check {
    margin-bottom: 30px;
    position: relative
}

.catchmentsmainfamily ul li:hover p,
.formdivfamily .form-control:focus,
.formdivfamily .form-control:hover {
    color: #fff
}

.formdivfamily .form-check label {
    font-size: 14px;
    color: #fff
}

.formdivfamily .form-check-input {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0
}

.formdivfamily .form-check-input[type=checkbox] {
    border-radius: 0
}

.formdivfamily .form-group {
    margin-bottom: 20px;
    position: relative
}

.formdivfamily .form-check .form-errors {
    left: 0;
    bottom: auto
}

.formdivfamily .form-errors {
    color: red;
    position: absolute;
    font-size: 12px !important;
    font-weight: 400;
    bottom: -40px
}

.formdivfamily .form-floating>label {
    color: rgba(255, 255, 255, .5)
}

.Aubussiness-mainfamily {
    width: 100%;
    background-color: #000;
    padding: 50px 0
}

.aboutmainfamily {
    width: 100%;
    background-image: url(/public/familyhubimg/images/about-bg.png);
    background-size: 100% 100%;
    padding-top: 150px;
    padding-bottom: 150px
}

.aboutmainfamily h2 {
    color: #fff;
    font-size: 22px;
    font-weight: 600;
    font-style: italic;
    line-height: 34px
}

.aboutmainfamily p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 25px;
    margin-top: 30px;
    padding: 0 100px
}

.AmenitiesSection {
    display: flex;
    justify-content: space-between;
}

.AmenitiesSection .Amenities-button {
    display: flex;
    gap: 1.5rem;
    position: relative;
    bottom: -2rem;
}

.AmenitiesSection .Amenities-button .Amenities-swiper-button-prev i, .AmenitiesSection .Amenities-button .Amenities-swiper-button-next i {
    color: #fff;
}

.AmenitiesSection .Amenities-button .Amenities-swiper-button-prev, .AmenitiesSection .Amenities-button .Amenities-swiper-button-next {
    background-color: #f7943e;
    height: 45px;
    aspect-ratio: 1 /1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.amenitiesmainfamily {
    padding: 100px 0
}

.amenitiesmainfamily h2 {
    font-size: 50px;
    font-weight: 300;
    margin-bottom: 50px;
    font-family: Montserrat, sans-serif !important
}

.amenitiesmainfamily img {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px
}

#AElifestyle img,
.Thegreenvistafamily img {
    border-radius: 100%
}

.Thegreenvistafamily .greenvista-swiper .swiper-wrapper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Thegreenvistafamily {
    padding: 100px 0;
    background-color: #f9f9f9;
    background-image: url('/public/familyhubimg/images/bg-small-light.png'), url('/public/familyhubimg/images/bg-big-light.png');
    background-repeat: no-repeat, no-repeat;
    background-position: 3% 10%, 130% 100%;
    background-size: auto auto, 34% 68%
}

.Thegreenvistafamily::after {
    content: '';
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #497b68
}

.Thegreenvistafamily h2 {
    font-weight: 300;
    margin-bottom: 30px
}

#AElifestyle img {
    width: 550px;
    height: 550px
}

.Thegreenvistafamily .owl-dots {
    display: block
}

.Thegreenvistafamily .item {
    text-align: center
}

.layoutplanmainfamily {
    width: 100%;
    padding: 100px 0;
    background-color: #497b68;
    background-image: url('/public/familyhubimg/images/big-bg-white.png');
    background-repeat: no-repeat;
    background-position: -29% 100%;
    background-size: 35% 35%
}

.layoutplanmainfamily h2 {
    font-size: 60px;
    font-weight: 300;
    margin-bottom: 40px;
    color: #fff
}

.layoutplanmainfamily img {
    width: 100%;
    margin-bottom: 100px
}

#nav-profile img {
    margin-bottom: 20px
}

.layoutplanmainfamily h3 {
    font-size: 60px;
    font-weight: 300;
    color: #fff
}

.contentdetailfamily ul,
.layoutplanmainfamily ul {
    margin: 0;
    padding: 0
}

.layoutplanmainfamily ul li {
    width: 50%;
    font-weight: 300;
    float: left;
    list-style-type: auto;
    margin-bottom: 20px;
    color: #fff;
    font-size: 16px
}

.layoutplanmainfamily ul li span {
    color: #fff;
    font-weight: 300
}

.layoutplanmainfamily .nav-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    margin: 0 auto 50px;
    border: none
}

.nav-tabs .nav-link {
    border: none;
    font-size: 60px;
    font-weight: 100;
    color: #fff;
    opacity: .5;
    text-align: center;
    width: 50%
}

li.nav-item {
    list-style-type: none !important
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    opacity: 1;
    color: #fff;
    border-bottom: 2px solid #fff;
    background-color: transparent
}

.Aubussinessmainfamily {
    width: 100%;
    padding: 100px 0;
    background-color: #f9f9f9;
    text-align: center
}

.Aubussinessmainfamily h3 {
    font-size: 60px;
    color: #000;
    text-align: center;
    font-weight: 300;
    margin-bottom: 40px
}

.Aubussinessmainfamily .logofamily {
    width: 200px;
    padding: 20px;
    transition: .5s;
    box-shadow: 30px 0 30px #e1e1e1;
    display: inline-block;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px
}

.Aubussinessmainfamily .logofamily img {
    width: 120px;
    transition: transform .2s;
    transform: translate(0, 0)
}

.Aubussinessmainfamily img {
    width: 150px
}

.Aubussinessmainfamily .logofamily:hover {
    transform: translate(-10%, 0);
    transition: transform .2s
}

.homeloadmainfamily {
    padding: 0 0 100px
}

.homeloanlogodivfamily {
    width: 100%;
    background-color: #497b68;
    padding: 50px 100px;
    border-radius: 24px
}

.banklogodivfamily {
    width: 48%;
    background-color: #fff;
    padding: 15px;
    text-align: center;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px
}

.banklogodivfamily img,
.catchmentsmainleftfamily img {
    width: 100%
}

.banklogomainfamily {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.homeloanlogodivfamily h3 {
    color: #fff;
    font-weight: 200;
    font-size: 40px;
    padding-top: 30px
}

.catchmentsmainfamily {
    padding: 100px 0;
    background-image: url('/public/familyhubimg/images/bg-big-light.png'), url('/public/familyhubimg/images/bg-small-light.png');
    background-repeat: no-repeat, no-repeat;
    background-position: -20% 50%, 95% 10%;
    background-size: 34% 68%, auto auto
}

.catchmentsmainfamily h3 {
    font-weight: 300;
    margin-bottom: 50px;
    text-align: center;
    margin-top: 35px
}

.catchmentsmainfamily ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between
}

.catchmentsmainfamily ul li {
    background-color: #f9f9f9;
    padding: 50px 15px;
    width: 24%;
    display: inline-table;
    text-align: center;
    border-radius: 16px;
    margin-bottom: 20px;
    transform: translate(0, 0);
    transition: .5s ease-in-out
}

.catchmentsmainfamily ul li:hover {
    transform: translate(0, -5%);
    transition: transform .5s;
    background-color: #497b68
}

.catchmentsmainfamily ul li:hover img {
    filter: brightness(0) invert(1)
}

.catchmentsmainfamily ul li img {
    margin-bottom: 15px
}

.catchmentsmainfamily ul li p {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    min-height: 40px;
    margin-bottom: 0
}

.slideronemainfamily {
    width: 100%;
    padding: 50px 0
}

.carddivfamily {
    width: 100%;
    padding: 0
}

.carddivfamily img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 16px
}

.constructionupdatesmainfamily #ownslider {
    margin-top: 0
}

.constructionSwiper .swiper-slide {
    height: initial;
    display: flex;
}

.constructionSwiper .swiper-slide img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily {
    display: flex;
    align-items: center;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily {
    margin-bottom: 50px;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily h2 {
    margin: 0;
    margin-right: auto;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily .form-control {
    margin: 0 auto;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily .construction-button {
    display: flex;
    gap: 1.5rem;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily .construction-button .construction-swiper-button-prev, .constructionupdatesmainfamily .constructionupdatesdivfamily .construction-button .construction-swiper-button-next {
    background-color: #f7943e;
    height: 45px;
    aspect-ratio: 1 /1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.constructionupdatesmainfamily .constructionupdatesdivfamily .construction-button i {
    color: #fff;
}

.footermainfamily {
    width: 100%;
    padding: 50px 0;
    background-color: #f9f9f9
}

.footer-logofamily {
    width: 70%
}

.footer-logofamily ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.footer-logofamily ul li {
    display: inline-block;
    width: 50%;
    text-align: center
}

.footer-logofamily ul li:first-child {
    border-right: 1px solid #000;
    text-align: left;
    width: 45%
}

.footer-logofamily ul li img {
    width: 100px
}

.footermainfamily h5 {
    color: #000;
    font-weight: 500
}

.footermainfamily h6 {
    color: #000;
    margin-top: 40px;
    font-size: 1.25rem
}

.footermainfamily p {
    color: #000;
    padding-right: 50px;
    font-weight: 400px;
    font-size: 16px
}

.gallerymainfamily {
    width: 100%;
    padding: 50px 0 100px
}

.gallerymainfamily .GallerySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gallerymainfamily .GallerySection .Gallery-button {
    display: flex;
    gap: 1.5rem;
}

.gallerymainfamily .GallerySection .Gallery-button i {
    color: #fff;
}

.gallerymainfamily .GallerySection .Gallery-button .Gallery-swiper-button-prev, .gallerymainfamily .GallerySection .Gallery-button .Gallery-swiper-button-next {
    background-color: #f7943e;
    height: 45px;
    aspect-ratio: 1 /1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gallerymainfamily .GallerySwiper .swiper-wrapper .swiper-slide {
    width: 100%;
    aspect-ratio: 67 / 45;
}

.gallerymainfamily .GallerySwiper .swiper-wrapper .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.constructionupdatesdivfamily h2,
.gallerymainfamily h2 {
    font-size: 60px;
    margin-bottom: 50px
}

.constructionupdatesmainfamily .owl-carousel .owl-item img {
    display: block;
    width: 100%;
    border-radius: 16px
}

.constructionupdatesmainfamily {
    width: 100%;
    padding: 50px 0 0
}

.navbar-toggler {
    border: none
}

.maplocation {
    color: #f7943e;
    padding-left: 50px
}

.mr-50 {
    margin-right: 60px
}

.ml-50 {
    margin-left: 60px
}

.ml-10 {
    margin-left: 20px
}

.navmenu li span {
    border-left: 1px solid #fff;
    float: left
}

.navmenu li span a {
    color: #fff;
    text-decoration: underline
}

.btnmainfamily {
    padding: 10px 50px;
    font-size: 18px;
    color: #fff;
    background-color: #f7943e;
    border: none;
    border-radius: 50px;
    transition: .5s ease-in-out
}

.btnmainfamily:hover {
    background-color: transparent;
    color: #fff;
    border: 1px solid #f15922
}

.index-page.scrolled .navmenu li span a {
    color: #f15922
}

.index-page.scrolled .navmenu li span {
    border-left: 1px solid #f15922;
    float: left
}

.contentdetailfamily ul li,
.social-logofamily ul li {
    list-style-type: none;
    margin-bottom: 15px
}

.contentdetailfamily ul li a,
.social-logofamily ul li a {
    color: #000;
    font-size: 16px
}

.contentdetailfamily ul li img {
    margin-right: 10px;
    width: 20px;
    filter: brightness(0) invert(0)
}

.social-logofamily h5 {
    margin-top: 40px
}

.social-logofamily ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center
}

.social-logofamily ul li img {
    margin-right: 15px;
    filter: brightness(0) invert(0);
    width: 35px
}

.footer-bottomfamily {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .5);
    margin-top: 30px;
    padding-top: 20px
}

.footer-bottomfamily span {
    color: #000;
    font-size: 12px
}

.constructionupdatesdivfamily {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.constructionupdatesdivfamily .form-control {
    width: 12%;
    background-color: #fff;
    border: 1px solid #f7943e;
    color: #000;
    border-radius: 50px;
    appearance: auto;
    margin-top: 20px;
    padding: 10px;
    margin-right: 10%
}

.constructionupdatesmainfamily .offers .owl-nav {
    position: absolute;
    top: -30%;
    width: 100%;
    display: block
}

.familyhubVideo {
    width: 100%;
    height: auto;
}

.familyhubVideo div {
    width: 100%;
    aspect-ratio: 16 / 5;
}

.familyhubVideo div iframe {
    width: 100%;
    height: 100%;
}

.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: #497b68;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: .4s
}

.scroll-top i {
    font-size: 24px;
    color: #fff;
    line-height: 0
}

.scroll-top:hover {
    background-color: rgba(73, 123, 104, .8);
    color: #fff
}

.scroll-top.active {
    visibility: visible;
    opacity: 1
}